// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import { findEl } from 'src/helpers/finders';

class FieldRulesController extends Controller {
  static targets = [
    'triggerFieldIcon',
    'triggerFieldArea',
    'triggerFieldInstructions',
    'triggerFieldNumber',
    'affectedFieldIcon',
    'affectedFieldInstructions',
    'affectedFieldNumbers',
    'affectedFieldPill',
    'affectedFieldTemplate',
    'clearAll',
    'saveButton',
  ];

  static values = { mode: String, newAffectedFields: Array<string> };

  triggerFieldIconTarget!: HTMLElement;
  triggerFieldAreaTarget!: HTMLElement;
  triggerFieldInstructionsTarget!: HTMLElement;
  triggerFieldNumberTarget!: HTMLInputElement;
  affectedFieldIconTarget!: HTMLElement;
  affectedFieldInstructionsTarget!: HTMLElement;
  affectedFieldNumbersTarget!: HTMLInputElement;
  affectedFieldPillTargets!: HTMLElement[];
  affectedFieldTemplateTarget!: HTMLTemplateElement;
  clearAllTarget!: HTMLElement;
  saveButtonTarget!: HTMLInputElement;

  modeValue: string;
  newAffectedFieldsValue: string[];
  hasTriggerFieldInstructionsTarget!: boolean;
  hasAffectedFieldInstructionsTarget!: boolean;

  connect(): void {
    this.newAffectedFieldsValue = [];
  }

  setTriggerMode(): void {
    this.modeValue = 'triggerMode';
    this.triggerFieldIconTarget.classList.add('mode-icon__selected');
    this.affectedFieldIconTarget.classList.remove('mode-icon__selected');
  }

  setAffectedMode(): void {
    this.modeValue = 'affectedMode';
    this.affectedFieldIconTarget.classList.add('mode-icon__selected');
    this.triggerFieldIconTarget.classList.remove('mode-icon__selected');
  }

  clearMode(): void {
    this.modeValue = '';
    this.triggerFieldIconTarget.classList.remove('mode-icon__selected');
    this.affectedFieldIconTarget.classList.remove('mode-icon__selected');
  }

  toggle(event: ActionEvent & DOMEvent): void {
    const {
      fieldNumber,
      fieldLabel,
      fieldType,
      choices,
    } = event.currentTarget.dataset;
    if (this.modeValue === 'triggerMode' && this.isTriggerFieldType(fieldType)) {
      this.toggleTrigger(fieldNumber, fieldLabel, choices);
    } else if (this.modeValue === 'affectedMode') {
      this.updateAffectedFieldsList(fieldNumber, fieldLabel);
    }
  }

  isTriggerFieldType(fieldType: string): boolean {
    return fieldType === 'Checkbox' || fieldType === 'Dropdown';
  }

  toggleTrigger(fieldNumber: string, fieldLabel: string, choices: string): void {
    if (this.triggerFieldNumberTarget.value === fieldNumber) {
      this.removeTrigger();
    } else {
      this.replaceTriggerNumber(fieldNumber, fieldLabel);
      this.replaceTriggerOptions(choices);
    }
  }

  replaceTriggerNumber(fieldNumber: string, fieldLabel: string): void {
    this.triggerFieldInstructionsTarget.classList.add('rule-area__hidden');
    this.triggerFieldAreaTarget.classList.remove('rule-area__hidden');

    const labelElement = findEl(this.triggerFieldAreaTarget, 'span', '.wb-pill');
    labelElement.firstChild.textContent = fieldLabel;

    this.unhighlightTrigger();

    const trigger = document.querySelector(`[data-field-number='${fieldNumber}']`);
    trigger.classList.add('trigger-field-highlight');

    this.triggerFieldNumberTarget.value = fieldNumber;
  }

  replaceTriggerOptions(choices: string): void {
    const options = choices ? JSON.parse(choices) : ['checked', 'unchecked'];
    const selectElement = findEl(this.triggerFieldAreaTarget, 'select');
    const [optionElement] = selectElement.children;

    const newOptions: [HTMLInputElement] =
      options.reduce(
        (accumulator: [HTMLInputElement], choice) => {
          const newOption = optionElement.cloneNode(true) as HTMLInputElement;
          newOption.value = choice;
          newOption.textContent = choice;
          newOption.removeAttribute('selected');
          accumulator.push(newOption);
          return accumulator;
        },
        [],
      );

    selectElement.replaceChildren(...newOptions);
  }

  updateAffectedFieldsList(fieldNumber: string, fieldLabel: string): void {
    this.affectedFieldInstructionsTarget.classList.add('rule-area__hidden');
    this.toggleClearAll('show');

    const affectedFieldNumbers =
      this.affectedFieldNumbersTarget.value.trim().split(' ');
    if (affectedFieldNumbers.includes(fieldNumber)) {
      const affectedFieldPill = this.affectedFieldPillTargets.find((pill) => {
        return pill.textContent.trim() === fieldLabel;
      });
      this.removeAffectedField(affectedFieldPill, fieldLabel);
    } else {
      this.addAffectedField(fieldNumber, fieldLabel);
    }
  }

  removeTrigger(): void {
    this.unhighlightTrigger();

    this.triggerFieldInstructionsTarget.classList
      .remove('rule-area__hidden');
    this.triggerFieldAreaTarget.classList.add('rule-area__hidden');

    this.triggerFieldNumberTarget.value = null;
  }

  addAffectedField(fieldNumber: string, fieldLabel: string): void {
    const template = this.affectedFieldTemplateTarget.content.firstElementChild;
    const newAffectedFieldElement = template.cloneNode(true) as HTMLElement;
    newAffectedFieldElement.firstChild.textContent = fieldLabel;
    this.clearAllTarget.before(newAffectedFieldElement);
    this.highlightAffectedField(fieldNumber);

    this.affectedFieldNumbersTarget.value += ` ${fieldNumber}`;
  }

  removeAffectedField(affectedFieldPill: HTMLSpanElement, label: string): void {
    const highlightedField: HTMLElement =
      document.querySelector(`[data-field-label='${label}']`);

    highlightedField.classList.remove('affected-field-highlight');

    affectedFieldPill.remove();

    const hiddenField =
      this.removeAffectedFieldValue(highlightedField.dataset.fieldNumber);

    if (hiddenField.value === '') {
      this.affectedFieldInstructionsTarget.classList.remove('rule-area__hidden');
      this.toggleClearAll('hide');
    }
  }

  removeAffectedFields(): void {
    document.querySelectorAll('.affected-field-highlight').forEach((element) => {
      element.classList.remove('affected-field-highlight');
    });

    this.affectedFieldPillTargets.forEach((pill) => { pill.remove(); });

    this.affectedFieldNumbersTarget.value = '';

    this.affectedFieldInstructionsTarget.classList.remove('rule-area__hidden');

    this.toggleClearAll('hide');
  }

  removeAffectedFieldPill(event: DOMEvent): void {
    const affectedFieldPill = event.currentTarget.parentElement;
    const label = affectedFieldPill.firstChild.textContent.trim();

    this.removeAffectedField(affectedFieldPill, label);
  }

  removeAffectedFieldValue(fieldNumber: string): HTMLInputElement {
    const hiddenField = this.affectedFieldNumbersTarget;

    hiddenField.value =
      hiddenField.value.replace(fieldNumber, '').replace(/\s+/gu, ' ').trim();

    return hiddenField;
  }

  highlightFields(event: DOMEvent): void {
    this.unhighlightFields();

    if (this.toggledSamePreviewButton(event.currentTarget)) { return; }

    const { triggerFieldNumber, affectedFieldNumbers } =
      event.currentTarget.dataset;
    const trigger =
      document.querySelector(`[data-field-number='${triggerFieldNumber}']`);

    if (trigger) { trigger.classList.add('trigger-field-highlight'); }

    JSON.parse(affectedFieldNumbers).forEach((number: string) => {
      this.highlightAffectedField(number);
    });
  }

  unhighlightFields(): void {
    this.unhighlightTrigger();

    document.querySelectorAll('.affected-field-highlight').forEach((element) => {
      element.classList.remove('affected-field-highlight');
    });
  }

  highlightAffectedField(fieldNumber: string): void {
    const field = document.querySelector(`[data-field-number='${fieldNumber}']`);
    if (field) {
      field.classList.add('affected-field-highlight');
    }
  }

  unhighlightTrigger(): void {
    const trigger = document.querySelector('.trigger-field-highlight');
    if (trigger) {
      trigger.classList.remove('trigger-field-highlight');
    }
  }

  toggledSamePreviewButton(element: HTMLElement): boolean {
    const selectedElements = document.querySelectorAll('.mode-icon__selected');

    selectedElements.forEach((selectedElement) => {
      selectedElement.classList.remove('mode-icon__selected');
    });

    if (Array.from(selectedElements).includes(element)) { return true; }

    element.classList.add('mode-icon__selected');
    return false;
  }

  toggleClearAll(action: string): void {
    if (action === 'hide') {
      this.clearAllTarget.classList.add('rule-area__hidden');
    } else if (action === 'show') {
      this.clearAllTarget.classList.remove('rule-area__hidden');
    }
  }

  validateFieldsSelected(event: DOMEvent): void {
    let errorMessage = '';

    if (this.instructionsVisible(this.triggerFieldInstructionsTarget)) {
      errorMessage = 'Please select a trigger field.';
    } else if (this.instructionsVisible(this.affectedFieldInstructionsTarget)) {
      errorMessage = 'Please select at least one show field.';
    }

    this.saveButtonTarget.setCustomValidity(errorMessage);
    this.saveButtonTarget.reportValidity();

    if (errorMessage) { event.preventDefault(); }
  }

  instructionsVisible(target: HTMLElement): boolean {
    return !target.classList.contains('rule-area__hidden');
  }
}

export default FieldRulesController;
