import { Controller } from '@hotwired/stimulus';

const MAX_SUGGESTIONS = 4;

type FormFillerInput = HTMLInputElement | HTMLTextAreaElement;
type FormFillerEvent = DOMEvent<FormFillerInput, FormFillerInput>;

class AutofillController extends Controller {
  static targets = ['option', 'popover', 'source'];

  currentInput!: FormFillerInput | null;
  currentOptionIndex!: number;
  optionTargets!: HTMLElement[];
  popoverTarget!: HTMLElement;
  sourceTargets!: HTMLInputElement[];

  get autofillOptions(): string[] {
    const options = this.sourceTargets
      .map((target) => { return target.value.trim(); })
      .filter((value) => { return value !== ''; })
      .sort();

    return [...new Set(options)];
  }

  connect(): void {
    this.currentInput = null;
    this.currentOptionIndex = -1;
    this.hidePopover();
  }

  /* These are methods that are triggered via various DOM events */

  hidePopover(): void {
    this.popoverTarget.classList.add('autofill-popover__hidden');
  }

  displayOptions(event: FormFillerEvent): void {
    this.currentInput = event.target;
    this.currentOptionIndex = -1;

    const term = event.target.value;
    const filteredOptions = this.autofillOptions.filter((option) => {
      return option.toLowerCase().startsWith(term.toLowerCase()) && option !== term;
    }).slice(0, MAX_SUGGESTIONS);

    if (filteredOptions.length === 0 || term === '') {
      this.hidePopover();
    } else {
      this.renderOptions(filteredOptions);
      this.showPopover(event);
    }
  }

  keyboardNavigation(event: KeyboardEvent): void {
    if (!this.popoverOpen()) { return; }

    if (event.key === 'Escape' || event.key === 'Enter') {
      event.preventDefault();
      this.hidePopover();
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      this.handleArrowPress(event.key);
    }
  }

  hoverOption(event: DOMEvent): void {
    const optionIndex = this.optionTargets.findIndex((option) => {
      return option === event.target;
    });

    this.currentOptionIndex = optionIndex;
    this.highlightSelectedOption();
  }

  selectOption(event: DOMEvent): void {
    const option = event.target.textContent;
    this.setSelected(option);
    this.hidePopover();
  }

  /* These are helper methods that are called by the above methods */

  popoverOpen(): boolean {
    return !this.popoverTarget.classList.contains('autofill-popover__hidden');
  }

  renderOptions(options: string[]): void {
    this.popoverTarget.innerHTML = '';

    const actions = [
      'mouseenter->autofill#hoverOption',
      'mousedown->autofill#selectOption',
    ].join(' ');

    options.forEach((option) => {
      const element = document.createElement('div');
      element.classList.add('autofill-option');
      element.setAttribute('data-autofill-target', 'option');
      element.setAttribute('data-action', actions);
      element.appendChild(document.createTextNode(option));
      this.popoverTarget.appendChild(element);
    });
  }

  showPopover(event: FormFillerEvent): void {
    // Cannot be hidden prior to calculating position, otherwise results are wrong
    this.popoverTarget.classList.remove('autofill-popover__hidden');
    const styles = this.calculatePopoverPosition(event.target);
    Object.assign(this.popoverTarget.style, styles);
  }

  calculatePopoverPosition(originalElement: HTMLElement): object {
    const minWidth = originalElement.offsetWidth;
    let left = 0;
    let top = originalElement.offsetHeight;
    let element = originalElement.offsetParent as HTMLElement;

    while (element !== this.popoverTarget.offsetParent) {
      left += element.offsetLeft;
      top += element.offsetTop;
      element = element.offsetParent as HTMLElement;
    }

    return { left: `${left}px`, minWidth: `${minWidth}px`, top: `${top}px` };
  }

  handleArrowPress(key: string): void {
    const maxIndex = this.optionTargets.length - 1;

    if (key === 'ArrowUp') {
      this.currentOptionIndex -= 1;
    } else if (key === 'ArrowDown') {
      this.currentOptionIndex += 1;
    }

    if (this.currentOptionIndex < -1) {
      this.currentOptionIndex = maxIndex;
    } else if (this.currentOptionIndex > maxIndex) {
      this.currentOptionIndex = -1;
    }

    const selectedOption = this.optionTargets[this.currentOptionIndex]?.textContent;
    this.setSelected(selectedOption);
    this.highlightSelectedOption();
  }

  setSelected(value: string | null): void {
    if (this.currentInput === null) { return; }

    this.currentInput.value = value || '';
  }

  highlightSelectedOption(): void {
    this.optionTargets.forEach((option, index) => {
      const active = this.currentOptionIndex === index;
      option.classList.toggle('autofill-option__active', active);
    });
  }
}

export default AutofillController;
