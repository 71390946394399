const LAST_ACTIVITY = {
  attribute: 'lastActivity',
  sortBy: 'last_activity',
  title: 'Last Activity',
};
const STATUS = {
  attribute: 'status',
  sortBy: 'status_and_seq_and_recipient',
  title: 'Status',
};
const WAITING_ON = {
  attribute: 'waitingOn',
  sortBy: 'signed_date_seq_recipient_and_status',
  title: 'Waiting On',
};
const COMPLETED = {
  attribute: 'lastActivity',
  sortBy: 'last_activity',
  title: 'Completed',
};
const DENIED = {
  attribute: 'lastActivity',
  sortBy: 'last_activity',
  title: 'Denied',
};
const ARCHIVED = {
  attribute: 'lastActivity',
  sortBy: 'last_activity',
  title: 'Archived',
};
const CANCELLED = {
  attribute: 'lastActivity',
  sortBy: 'last_activity',
  title: 'Cancelled',
};

const GROUP_CAMPAIGN_STATUS_COLUMNS = {
  active: [LAST_ACTIVITY, STATUS],
  archived: ARCHIVED,
  cancelled: CANCELLED,
  completed: COMPLETED,
  pending: [LAST_ACTIVITY, STATUS],
};

const LINK_CAMPAIGN_STATUS_COLUMNS = {
  active: [LAST_ACTIVITY, STATUS],
  archived: ARCHIVED,
  cancelled: CANCELLED,
  completed: COMPLETED,
  denied: DENIED,
  pending: [LAST_ACTIVITY, STATUS],
};

const ROUTED_GROUP_CAMPAIGN_STATUS_COLUMNS = {
  active: [LAST_ACTIVITY, STATUS, WAITING_ON],
  archived: ARCHIVED,
  cancelled: CANCELLED,
  completed: COMPLETED,
  denied: DENIED,
  pending: [LAST_ACTIVITY, STATUS, WAITING_ON],
};

const ROUTED_CAMPAIGN_STATUS_COLUMNS = {
  active: [LAST_ACTIVITY, STATUS, WAITING_ON],
  archived: ARCHIVED,
  cancelled: CANCELLED,
  completed: COMPLETED,
  denied: DENIED,
  pending: [LAST_ACTIVITY, STATUS, WAITING_ON],
};

const GROUP_CAMPAIGN_CORE_COLUMNS = [
  {
    attribute: 'formRecipients',
    sortBy: 'status_and_recipient',
    title: 'Recipient',
  },
  { attribute: 'subjectName', sortBy: 'subject', title: 'Form For' },
];

const ROUTED_GROUP_CAMPAIGN_CORE_COLUMNS = [
  { attribute: 'subjectName', sortBy: 'subject', title: 'Form For' },
];

const LINK_CAMPAIGN_CORE_COLUMNS = [
  { attribute: 'initiatorName', sortBy: 'initiator', title: 'Initiated By' },
];

const ROUTED_CAMPAIGN_CORE_COLUMNS = [
  { attribute: 'initiatorName', sortBy: 'initiator', title: 'Initiated By' },
];

export const TRACKER_FIELDS_COLUMN = {
  attribute: 'description', title: 'Description',
};

export const STATUS_COLUMNS = {
  GroupCampaign: GROUP_CAMPAIGN_STATUS_COLUMNS,
  LinkCampaign: LINK_CAMPAIGN_STATUS_COLUMNS,
  RoutedGroupCampaign: ROUTED_GROUP_CAMPAIGN_STATUS_COLUMNS,
  RoutedLinkCampaign: ROUTED_CAMPAIGN_STATUS_COLUMNS,
};

export const CORE_COLUMNS = {
  GroupCampaign: GROUP_CAMPAIGN_CORE_COLUMNS,
  LinkCampaign: LINK_CAMPAIGN_CORE_COLUMNS,
  RoutedGroupCampaign: ROUTED_GROUP_CAMPAIGN_CORE_COLUMNS,
  RoutedLinkCampaign: ROUTED_CAMPAIGN_CORE_COLUMNS,
};
