import classNames from 'classnames';
import React from 'react';
import Icon from 'src/common/components/icon';

type Props = {
  label: string;
  selected: boolean;
};

function Attachment(props: Props): React.JSX.Element {
  const { selected, label } = props;
  return (
    <div
      className={
        classNames(
          'text-input',
          'attachment-field',
          'requested-field',
          'adjustable-font',
          'field-container',
          { selected },
        )
      }
    >
      <Icon name='paperclip' /> {label}
    </div>
  );
}

export default Attachment;
