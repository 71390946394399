// @ts-strict-ignore
import React, { ReactNode } from 'react';

import Icon from 'src/common/components/icon';

type Props = {
  filterKey: number | string;
  filterLabel: string;
  filterValue: object;
  from: string;
  to: string;
  headerRange: string;
};

class DateRangeFilterModal extends React.Component<Props> {
  render(): ReactNode {
    const { from, to } = this.props;

    return (
      <div className='wb-modal'>
        <div className='wb-modal--header' id='dialog--title'>
          {this.modalTitle(from, to)}
          <button
            aria-label='Close'
            className='wb-modal--close-button'
            data-a11y-dialog-hide
            type='button'
          >
            <Icon name='close' size='1-5x' />
          </button>
        </div>
        <div className='wb-modal--body'>
          {this.modalBody(from, to)}
        </div>
        <div className='wb-modal--footer'>
          {this.modalFooter(from, to)}
        </div>
      </div>
    );
  }

  modalTitle(from: string, to: string): React.JSX.Element {
    return (
      <h1 className='wb-heading-4'>
        {this.renderHeaderText(from, to)}
      </h1>
    );
  }

  modalBody(from: string, to: string): React.JSX.Element {
    return (
      <div className='wb-l-split'>
        <label className='wb-label width-40-percent wb-u-margin-l-4'>
          From
          <input
            className='wb-input-field'
            data-action='date-range#setFrom'
            id='gte'
            name='gte'
            type='date'
            value={from}
          />
        </label>
        <label className='wb-label width-40-percent wb-u-margin-l-4'>
          To
          <input
            className='wb-input-field'
            data-action='date-range#setTo'
            id='lte'
            min={from}
            name='lte'
            type='date'
            value={to}
          />
        </label>
      </div>
    );
  }

  modalFooter(from: string, to: string): React.JSX.Element {
    return (
      <div className='modal-btns' data-controller='responses-filter'>
        <button className='btn btn-link' data-a11y-dialog-hide type='button'>
          Cancel
        </button>
        <button
          className='btn btn-primary'
          data-action='responses-filter#applyFilter'
          data-responses-filter-key-param={this.props.filterKey}
          data-responses-filter-value-param={JSON.stringify(this.props.filterValue)}
          disabled={!from || !to}
          type='submit'
        >
          Add filter
        </button>
      </div>
    );
  }

  renderHeaderText(from: string, to: string): React.JSX.Element {
    const { filterLabel, headerRange } = this.props;

    if (!from || !to) {
      return <span>{`Select a range for '${filterLabel}'`}</span>;
    }
    return (
      <span>
        {filterLabel}: &nbsp;
        <strong>
          {headerRange}
        </strong>&nbsp;
        <small>
          <a
            data-action='date-range#reset'
            href='#!'
            id='reset'
          >
            Reset
          </a>
        </small>
      </span>
    );
  }
}

export default DateRangeFilterModal;
