// @ts-strict-ignore
import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';

import DateRangeFilterModal from 'src/responses/components/date_range_filter_modal';
import { browserFormatDate } from 'src/helpers/date';

class DateRangeController extends Controller {
  static values = {
    filterKey: String,
    filterLabel: String,
    filterValue: Object,
    from: String,
    headerRange: String,
    to: String,
  };

  filterKeyValue!: string;
  filterLabelValue!: string;
  filterValueValue: object;
  fromValue: string;
  toValue: string;
  headerRangeValue: string;

  connect(): void {
    this.updateDisplay();
  }

  component(): React.JSX.Element {
    return (
      <React.StrictMode>
        <DateRangeFilterModal
          filterKey={this.filterKeyValue}
          filterLabel={this.filterLabelValue}
          filterValue={this.filterValueValue}
          from={this.fromValue}
          headerRange={this.headerRangeValue}
          to={this.toValue}
        />
      </React.StrictMode>
    );
  }

  updateDisplay(): void {
    ReactDOM.render(this.component(), this.element);
  }

  setFrom(event: ActionEvent & DOMEvent<HTMLInputElement, HTMLInputElement>): void {
    this.fromValue = event.target.value;

    if (this.toValue < event.target.value) {
      this.toValue = event.target.value;
      this.setHeader();
    }
    this.fromValue = event.target.value;

    this.setHeader();

    this.setFilterValue();

    this.updateDisplay();
  }

  setTo(event: ActionEvent & DOMEvent<HTMLInputElement, HTMLInputElement>): void {
    this.toValue = event.target.value;
    this.setHeader();

    this.setFilterValue();

    this.updateDisplay();
  }

  setHeader(): void {
    this.headerRangeValue = `
      ${headerFormatDate(this.fromValue)} - ${headerFormatDate(this.toValue)}
    `;
  }

  reset(event: Event): void {
    event.preventDefault();

    this.fromValue = '';
    this.toValue = '';
    this.filterValueValue = {};

    this.updateDisplay();
  }

  setFilterValue(): void {
    const fromDate = formatDateToLocalTime(this.fromValue);
    const toDate = formatDateToLocalTime(this.toValue);

    this.filterValueValue = {
      gte: browserFormatDate(fromDate),
      lte: browserFormatDate(toDate),
    };
  }

  disconnect(): void {
    ReactDOM.unmountComponentAtNode(this.element);
  }
}

function headerFormatDate(dateString: string): string {
  const date = formatDateToLocalTime(dateString);
  const monthName = date.toLocaleString('en-us', { month: 'short' });
  const day = date.getDate();

  return `${monthName} ${day}`;
}

function formatDateToLocalTime(dateString: string): Date {
  const [year, month, day] = dateString.split('-').map(Number);

  return new Date(year, month - 1, day);
}

export default DateRangeController;
